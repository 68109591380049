import { Multicall } from "ethereum-multicall";

import MLMABI from "../../ABI/MLMABI.json";
import BEP20ABI from "../../ABI/BEP20.json";
import config from "../../config/config";

import {
    isAddress,
    getFormatMulticall,
    encryptData,
    decryptData
} from "../../helper/custom";
import { connection } from "../../helper/connection";
import { convert } from "../../helper/convert";
import { saveUsers, saveTx } from "../../Api/Actions";
import store from '../../store';

import { checkUsers } from "../../Api/Actions";


export function getReferalAddr(name) {

    try {
        //var name = "ref";
        var url = window.location.href
        //eslint-disable-next-line
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&;]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        if (results && results[2]) {
            var refAddr = decodeURIComponent(results[2].replace(/\+/g, ' '));
            return refAddr;
        }
    } catch (err) {
        return "";
    }

}

export async function checkUser() {

    try {
        var get = await connection();
        var web3 = get.web3;
        var address = get.address;
        var Contract = new web3.eth.Contract(MLMABI, config.contract);
        var userinfo = await Contract.methods.users(
            address
        ).call();
        return {
            isExist: userinfo.isExist,
        }
    } catch (err) {
        return {
            isExist: false
        }
    }
}

export async function getAdminWallet() {

    try {
        var get = await connection();
        var web3 = get.web3;
        var Contract = new web3.eth.Contract(MLMABI, config.contract);
        var ownerWallet = await Contract.methods.ownerWallet().call();
        return {
            ownerWallet,
        }
    } catch (err) {
        return {
            ownerWallet: ""
        }
    }
}

export async function validation(refaddress, email, type, phoneCode) {

    var error = "";
    var joinAmt = 0;
    var isAllowance = true;
    var get = await connection();
    var web3 = get.web3;
    var address = get.address;

    //eslint-disable-next-line
    let emailRegex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;

    try {
        // if (email === "") {
        //     error = "Please enter email id";
        // } else if (!emailRegex.test(email)) {
        //     error = "Email is invalid";
        // } 
        // else 
        if (get.connect === "no") {
            error = "Please connect smart chain network";
        } else if (refaddress === "") {
            error = "Please enter address";
        } else if (!isAddress(refaddress)) {
            error = "Please enter valid address";
        } else {

            const multicall = new Multicall({
                web3Instance: web3,
            });

            var Contract = [
                {
                    reference: "balanceOf",
                    contractAddress: config.tokenAddress,
                    abi: BEP20ABI,
                    calls: [
                        {
                            reference: "balanceOf",
                            methodName: "balanceOf",
                            methodParameters: [address],
                        },
                        {
                            reference: "allowance",
                            methodName: "allowance",
                            methodParameters: [address, config.contract],
                        },
                    ]
                },
                {
                    reference: "userinfo",
                    contractAddress: config.contract,
                    abi: MLMABI,
                    calls: [
                        {
                            reference: "users",
                            methodName: "users",
                            methodParameters: [refaddress],
                        },
                        {
                            reference: "users",
                            methodName: "users",
                            methodParameters: [address],
                        },
                        {
                            reference: "joinAmount",
                            methodName: "joinAmount",
                            methodParameters: [],
                        }
                    ]
                }
            ];

            const results = await multicall.call(Contract);
            var balanceOf = await getFormatMulticall(results, "balanceOf", 0);
            balanceOf = parseInt(balanceOf.hex) / 10 ** 18;
            var allowance = await getFormatMulticall(results, "balanceOf", 1);
            allowance = parseInt(allowance.hex) / 10 ** 18;
            let referinfo = await getFormatMulticall(results, "userinfo", 0);
            let userinfo = await getFormatMulticall(results, "userinfo", 1);
            var JoinAmount = await getFormatMulticall(results, "userinfo", 2);

            JoinAmount = parseInt(JoinAmount.hex) / 10 ** 18;
            joinAmt = JoinAmount;

            if (userinfo) {
                error = "Already joined this address";
            } else if (address.toLowerCase() === refaddress.toLowerCase()) {
                error = "wallet address & referral address should not be same";
            } else if (!referinfo) {
                error = "Invalid referral address";
            } else if (JoinAmount > balanceOf) {
                error = `Please make sure you have ${joinAmt} USDT in your wallet`;
            }
            // let userData = {
            //     signin: email,
            //     registertype: type,
            //     code: phoneCode
            // };

            // let { result } = await checkUsers(userData);


            // var errormsg = `Please enter Dezire Exchange registered email id`;
            // var errormsg1 = `Please verify your email in Dezire Exchange`;
            // var errormsg2 = `Already joined this email id`;
            // if (type == 2) {
            //     errormsg = `Please enter Dezire Exchange registered phone number`;
            //     errormsg1 = `Please verify your phone number in Dezire Exchange`;
            //     errormsg2 = `Already joined this phone number`;
            // }

            // if (result && result.verifyStatus === "unverified") {
            //     error = errormsg1;
            // } else if (result && result.already === "yes") {
            //     error = errormsg2;
            // } else if (result && result.isExits === "no") {
            //     error = errormsg;
            // }

            if (allowance >= JoinAmount) {
                isAllowance = false;
            }
        }
    } catch (err) {
        console.log(err, 'validation errerrerrerr')
        error = "Oops something went wrong.!"
    }

    return {
        errorvalidate: error,
        isAllowance: isAllowance,
        joinAmt
    };

}

export async function approveToken() {

    try {

        var currentProvider = store.getState();
        var balances = currentProvider.balances;
        var balance = parseFloat(balances.balance);

        if (balance === 0) {
            return {
                isAllowed: false,
                approvalAmt: 0,
                error: `Please make sure you have gas fee in your wallet.`
            }
        }

        var get = await connection();
        var web3 = get.web3;
        var address = get.address;
        var gasPrice = await web3.eth.getGasPrice();
        var Contract = new web3.eth.Contract(BEP20ABI, config.tokenAddress);
        var approveAmt = 100000 * (10 ** 18);
        approveAmt = await convert(approveAmt);

        var estimateGas = await Contract.methods.approve(
            config.contract,
            approveAmt.toString()
        ).estimateGas({ from: address });

        estimateGas = estimateGas + 100000;

        if (parseFloat(estimateGas) / 10 ** 8 > balance) {
            return {
                isAllowed: false,
                approvalAmt: 0,
                error: `Please make sure you have gas fee(${parseFloat(estimateGas) / 10 ** 8} BNB) in your wallet.`
            }
        }


        var result = await Contract.methods.approve(
            config.contract,
            approveAmt.toString()
        ).send({ from: address, gasLimit: estimateGas, gasPrice: gasPrice });

        var approvalAmt = (result && result.events && result.events.Approval && result.events.Approval.returnValues
            && result.events.Approval.returnValues && result.events.Approval.returnValues
            && result.events.Approval.returnValues.value) ?
            parseFloat(result.events.Approval.returnValues.value) : 0
        approvalAmt = approvalAmt / 10 ** 18;

        return {
            approvalAmt: approvalAmt,
            isAllowed: (result && result.status) ? result.status : false,
            error: ""
        }
    } catch (err) {
        console.log(err, 'approveToken errerrerr')


        var errMsg = (err && err.message) ? err.message.toString() : err;
        var pos = errMsg.search("User denied transaction signature");
        if (pos >= 0) {
            errMsg = "Cancelled"
        } else {
            errMsg = "Failed to approved"
        }

        return {
            approvalAmt: 0,
            isAllowed: false,
            error: errMsg
        }
    }

}

export async function JoinPlan(data) {

    try {

        var currentProvider = store.getState();
        var balances = currentProvider.balances;
        var balance = parseFloat(balances.balance);

        if (balance === 0) {
            return {
                result: null,
                errMsg: `Please make sure you have gas fee in your wallet.`
            }
        }

        var get = await connection();
        var web3 = get.web3;
        var address = get.address;
        var gasPrice = await web3.eth.getGasPrice();
        var Contract = new web3.eth.Contract(MLMABI, config.contract);
        var Amt = data.joinAmt * (10 ** 18);
        Amt = await convert(Amt.toString());;
        var type = 1;
        var email = data.email;
        if (data && data.isMobile == true) {
            type = 2;
            email = data.phoneNo;
        }

        var isType = true;
        if (data && data.isType == "right") {
            isType = false;
        }

        data.phoneCode = (data.phoneCode == "") ? "0" : data.phoneCode;
        // data.phoneCode = encryptData(data.phoneCode);
        email = encryptData(email)

        var estimateGas = await Contract.methods.regUser(
            data.ref,
            Amt.toString(),
            email.toString(),
            type.toString(),
            data.phoneCode.toString(),
            isType
        ).estimateGas({ from: address });
        estimateGas = estimateGas + 10000;

        if (parseFloat(estimateGas) / 10 ** 8 > balance) {
            return {
                result: null,
                errMsg: `Please make sure you have gas fee(${parseFloat(estimateGas) / 10 ** 8} BNB) in your wallet.`
            }
        }

        var result = await Contract.methods.regUser(
            data.ref,
            Amt.toString(),
            email.toString(),
            type.toString(),
            data.phoneCode.toString(),
            isType
        ).send({ from: address, gasLimit: estimateGas, gasPrice: gasPrice });
        var getEvtVal = null;
        try {
            getEvtVal = await Contract.getPastEvents("regLevelEvent", { fromBlock: parseFloat(result.blockNumber), toBlock: parseFloat(result.blockNumber) });
        } catch (err) {
            console.log(err, 'regLevelEvent errerrerr11')
        }


        var events = (getEvtVal && getEvtVal[0] && getEvtVal[0].returnValues) ? getEvtVal[0].returnValues : {};
        var getEmail = (events && events.email) ? events.email : ""
        var decry = decryptData(getEmail)

        let hash = (result && result.transactionHash) ? result.transactionHash : ""
        let userData = {
            amount: (events && events.referAmount) ? parseFloat(events.referAmount) / 10 ** 18 : 5,
            address: address,
            referer: data.ref,
            signin: decryptData(email),
            subscriptionFee: (events && events.subscriptionFee) ? parseFloat(events.subscriptionFee) / 10 ** 18 : 0,
            txtype: "referal",
            registertype: type,
            hash,
            code: data.phoneCode.toString()
        }

        console.log(userData, 'userDatauserDatauserData')

        await saveUsers(userData);
        await saveTx(userData);

        return {
            result: result,
            errMsg: ""
        }
    } catch (err) {
        console.log(err, 'JoinPlan errerrerr11')
        var errMsg = err.toString();
        var pos = errMsg.search("insufficient");
        if (pos >= 0) {
            errMsg = "Please make sure you have gas fee in your wallet"
        } else {
            errMsg = "Failed to join"
        }
        return {
            result: "",
            errMsg: errMsg
        }
    }

}

